<template>
  <b10-legend
    :showing.sync="internalShowing"
  >
    <template
      slot="legend"
    >
      <v-simple-table>
        <template
          #default
        >
          <tbody>
            <tr>
              <td>
                <v-chip
                  :color="PEDIDO_CLIENTE.colores.pendiente"
                  dark
                >
                  {{ PEDIDO_CLIENTE.alias.pendiente }}
                </v-chip>
              </td>
              <td>
                {{ PEDIDO_CLIENTE.descripciones.pendiente }}
              </td>
            </tr>
            <tr>
              <td>
                <v-chip
                  :color="PEDIDO_CLIENTE.colores.sirviendo"
                  dark
                >
                  {{ PEDIDO_CLIENTE.alias.sirviendo }}
                </v-chip>
              </td>
              <td>
                {{ PEDIDO_CLIENTE.descripciones.sirviendo }}
              </td>
            </tr>
            <tr>
              <td>
                <v-chip
                  :color="PEDIDO_CLIENTE.colores.servido"
                  dark
                >
                  {{ PEDIDO_CLIENTE.alias.servido }}
                </v-chip>
              </td>
              <td>
                {{ PEDIDO_CLIENTE.descripciones.servido }}
              </td>
            </tr>
            <tr>
              <td>
                <v-chip
                  :color="PEDIDO_CLIENTE.colores.finalizado"
                  dark
                >
                  {{ PEDIDO_CLIENTE.alias.finalizado }}
                </v-chip>
              </td>
              <td>
                {{ PEDIDO_CLIENTE.descripciones.finalizado }}
              </td>
            </tr>
            <tr>
              <td>
                <v-chip
                  color="red"
                  dark
                  small
                  label
                >
                  dd/mm/yyyy
                </v-chip>
              </td>
              <td>
                Pedido retrasado (fecha prevista de servicio)
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </template>
  </b10-legend>
</template>

<script>
import internalShowingMixin from '@/mixins/internalShowingMixin'
import { PEDIDO_CLIENTE } from '@/utils/consts'

export default {
  mixins: [internalShowingMixin],
  data () {
    return {
      PEDIDO_CLIENTE
    }
  }
}
</script>
